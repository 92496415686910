import { USER_PERMISSION_G1 } from "@/shared/variables/UserPermissionVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"
import { ACCOUNT_FLAG_ALLOW_BI_HORUSBI } from "@/router/routes/settings/variables/AccountFlagsVariables"

export default [
    {
        name: "HorusBiIndex.index",
        path: "bi",
        components: {
            default: () => import("@/pages/reports/horusBi/HorusBiIndex"),
        },
        beforeEnter:  onlyAllowedFlag(ACCOUNT_FLAG_ALLOW_BI_HORUSBI),
        meta: {
            pageTitle: "BI",
            mixpanelTrackOrigin: "BI",
            accessPermission: USER_PERMISSION_G1,
        },
    },
]
