import { definePreset } from "@primevue/themes"
import Aura from "@primevue/themes/aura"
import base from "./base"
import chip from "./chip"

export default definePreset(Aura, {
    ...base,
    components: {
        chip,
    },
})
