<template>
    <div class="login-form">
        <form
            class="login-form__wrapper"
            @submit.prevent="submitForm($event)"
        >
            <div class="input-holder">
                <label>E-mail</label>
                <input
                    v-model="form.username"
                    type="email"
                    placeholder="Digite seu e-mail"
                    required
                >
            </div>
            <div class="input-holder">
                <label>Senha</label>
                <input
                    v-model="form.password"
                    type="password"
                    placeholder="Digite sua senha"
                    required
                >
            </div>

            <slot name="message" />

            <button
                :class="[
                    'p-btn',
                    'login-form__submit',
                    'p-btn--' + (processingLogin === 2 ? 'success' : 'primary')
                ]"
                :disabled="processingLogin !== 0"
            >
                <span v-if="processingLogin === 1">
                    <pipe-icon
                        i="refresh"
                        class="fa-spin"
                    /> Autenticando</span>
                <span v-else-if="processingLogin === 2">
                    <pipe-icon i="check" /> Autenticado</span>
                <span v-else>Entrar</span>
            </button>

            <div class="text-center">
                <router-link
                    :to="{ name: 'recoverPassword.auth' }"
                    class="p-a p-a--alt"
                >
                    Esqueci minha senha
                </router-link>
            </div>
        </form>

        <div class="login-form__wrapper text-center">
            <p class="m-n">
                Não tem uma conta?
            </p>
            <a
                href="https://crmpiperun.com/trial/"
                target="_blank"
                rel="noopener noreferrer"
                class="p-a"
            >
                Crie uma agora mesmo!
            </a>
        </div>
    </div>
</template>

<script>
import PipeIcon from "@/components/formatters/PipeIcon"

export default {
    components: {
        PipeIcon,
    },
    props: {
        processingLogin: {
            type: Number,
            default: 0,
        },
    },
    emits: ["submit"],
    data() {
        return {
            form: {
                username: "",
                password: "",
            },
        }
    },
    methods: {
        submitForm() {
            this.$emit("submit", this.form)
        },
    },
}
</script>

<style lang="less">
.login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    &__main {
        padding-top: auto;
    }

    &__submit {
        margin-bottom: 16px;
    }

    .p-btn {
        width: 100%;
    }
}
</style>
