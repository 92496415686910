import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"

export default [
    {
        name: "dealRequiredFieldRules.index",
        path: "settings/deal-required-field-rules",
        component: () => import("@/pages/dealRequiredFieldRules/DealRequiredFieldRulesIndex"),
        beforeEnter(to, from, next) {
            const currentUser = getStore().getters["auth/currentUser"]
            if (!currentUser.account.flags.allows_deal_required_field_rules) {
                return next({ name: "notfound", query: { errorId: 403 } })
            }

            next()
        },
        meta: {
            title: "Regras de preenchimento de campos",
            description: "Gerencie a obrigatoriedade do preenchimento de campos nativos e/ou customizados para realizar ações nas oportunidades.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Regras de preenchimento de campos",
                    show: ".*",
                },
            ],
        },
    },
]
