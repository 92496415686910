import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { TYPE_CATEGORY, TYPE_TAG } from "@/shared/variables/talk/TalkGroupingVariables"
import { getStore } from "@/store"

const SERVICES_META = {
    title: "Serviços",
    description: "Gerencie seus serviços, crie grupos e subgrupos.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Serviços",
            show: ".*",
        },
    ],
}

const TAGS_META = {
    title: "Tags de atendimento",
    description: "Gerencie suas tags de atendimento.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Tags de atendimento",
            show: ".*",
        },
    ],
}

const CATEGORIES_META = {
    title: "Categorias",
    description: "Gerencie suas categorias de atendimento.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Categorias de atendimento",
            show: ".*",
        },
    ],
}

const EVENTS_META = {
    title: "Eventos",
    description: "Gerencie seus eventos de atendimento.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Eventos de atendimento",
            show: ".*",
        },
    ],
}

const TERMINALS_META = {
    title: "Contas de canais",
    description: "Gerencie suas contas de canais de atendimento.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Contas de canais",
            show: ".*",
        },
    ],
}

const PRIORITIES_META = {
    title: "Prioridades",
    description: "Gerencie suas prioridades de atendimento.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Prioridades de atendimento",
            show: ".*",
        },
    ],
}

const TEMPLATES_HSM_META = {
    title: "Templates de WhatsApp (HSM)",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Templates de WhatsApp (HSM)",
            show: ".*",
        },
    ],
}

const BLACKLIST_PHONES_META = {
    title: "Lista de bloqueio de telefones",
    description: "Gerencie sua lista de bloqueio de telefones.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Lista de bloqueio de telefones",
            show: ".*",
        },
    ],
}

const TEMPLATES_MESSAGE_META = {
    title: "Templates de mensagem",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Templates de mensagem",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "settings/talk",
        component: () => import("@/layouts/EmptyRouterLayout"),
        beforeEnter: onlyAuthorizedRouteEnter,
        children: [
            {
                name: "settings.talk.services.index",
                path: "services",
                component: () => import("@/pages/talkSettings/grouping/TalkServicesIndex"),
                meta: SERVICES_META,
                children: [
                    {
                        name: "settings.talk.services.create",
                        path: "create",
                        component: () => import("@/pages/talkSettings/grouping/TalkGroupingCreate"),
                    },
                    {
                        name: "settings.talk.services.edit",
                        path: ":id/edit",
                        props: true,
                        component: () => import("@/pages/talkSettings/grouping/TalkGroupingEdit"),
                    },
                ],
            },
            {
                name: "settings.talk.tags.index",
                path: "tags",
                component: () => import("@/pages/talkSettings/grouping/TalkGroupingIndex"),
                props: {
                    groupingType: TYPE_TAG,
                },
                meta: TAGS_META,
                children: [
                    {
                        name: "settings.talk.tags.create",
                        path: "create",
                        component: () => import("@/pages/talkSettings/grouping/TalkGroupingCreate"),
                    },
                    {
                        name: "settings.talk.tags.edit",
                        path: ":id/edit",
                        props: true,
                        component: () => import("@/pages/talkSettings/grouping/TalkGroupingEdit"),
                    },
                ],
            },
            {
                name: "settings.talk.categories.index",
                path: "categories",
                component: () => import("@/pages/talkSettings/grouping/TalkGroupingIndex"),
                props: {
                    groupingType: TYPE_CATEGORY,
                },
                meta: CATEGORIES_META,
                children: [
                    {
                        name: "settings.talk.categories.create",
                        path: "create",
                        component: () => import("@/pages/talkSettings/grouping/TalkGroupingCreate"),
                    },
                    {
                        name: "settings.talk.categories.edit",
                        path: ":id/edit",
                        props: true,
                        component: () => import("@/pages/talkSettings/grouping/TalkGroupingEdit"),
                    },
                ],
            },
            {
                name: "settings.talk.event-reasons.index",
                path: "event-reasons",
                component: () => import("@/pages/talkSettings/eventReason/TalkEventReasonIndex"),
                meta: EVENTS_META,
                children: [
                    {
                        name: "settings.talk.event-reasons.create",
                        path: "create",
                        component: () => import("@/pages/talkSettings/eventReason/TalkEventReasonCreate"),
                    },
                    {
                        name: "settings.talk.event-reasons.edit",
                        path: ":id/edit",
                        props: true,
                        component: () => import("@/pages/talkSettings/eventReason/TalkEventReasonEdit"),
                    },
                ],
            },
            {
                path: "terminals",
                component: () => import("@/layouts/EmptyRouterLayout"),
                children: [
                    {
                        name: "settings.talk.terminals.index",
                        path: "",
                        meta: TERMINALS_META,
                        component: () => import("@/pages/talkSettings/terminals/TalkTerminalsIndex"),
                    },
                    {
                        name: "settings.talk.terminals.create",
                        path: "create",
                        component: () => import("@/pages/talkSettings/terminals/TalkTerminalCreate"),
                    },
                    {
                        name: "settings.talk.terminals.edit",
                        path: ":id/edit",
                        props: true,
                        component: () => import("@/pages/talkSettings/terminals/TalkTerminalEdit"),
                    },
                    {
                        name: "settings.talk.terminals.menu",
                        path: ":id/menu",
                        props: true,
                        component: () => import("@/pages/talkSettings/terminals/TalkTerminalMenu"),
                    },
                    {
                        name: "settings.talk.terminals.menu-sections",
                        path: ":id/menu-sections",
                        props: true,
                        component: () => import("@/pages/talkSettings/terminals/TalkTerminalMenuSections"),
                    },
                ],
            },
            {
                name: "settings.talk.priorities.index",
                path: "priorities",
                component: () => import("@/pages/talkSettings/priority/TalkPrioritiesIndex"),
                meta: PRIORITIES_META,
                children: [
                    {
                        name: "settings.talk.priorities.create",
                        path: "create",
                        component: () => import("@/pages/talkSettings/priority/TalkPriorityCreate"),
                    },
                    {
                        name: "settings.talk.priorities.edit",
                        path: ":id/edit",
                        props: true,
                        component: () => import("@/pages/talkSettings/priority/TalkPriorityEdit"),
                    },
                ],
            },
            {
                path: "templates/hsm",
                component: () => import("@/layouts/EmptyRouterLayout"),
                meta: TEMPLATES_HSM_META,
                children: [
                    {
                        name: "settings.talk.templates.hsm.index",
                        path: "",
                        component: () => import("@/pages/talkSettings/template/hsm/TalkTemplateHsmIndex"),
                    },
                    {
                        name: "settings.talk.templates.hsm.create",
                        path: "create",
                        component: () => import("@/pages/talkSettings/template/hsm/TalkTemplateHsmCreate"),
                        meta: {
                            title: "Novo template de mensagem HSM",
                            breadcrumb: [
                                ...SettingsBreadcrumbs,
                                {
                                    label: "Templates de WhatsApp (HSM)",
                                    href: { name: "settings.talk.templates.hsm.index" },
                                    show: ".*",
                                },
                                {
                                    label: "Criar template HSM",
                                    show: ".*",
                                },
                            ],
                        },
                    },
                    {
                        name: "settings.talk.templates.hsm.edit",
                        path: ":id/edit",
                        props: true,
                        component: () => import("@/pages/talkSettings/template/hsm/TalkTemplateHsmEdit"),
                        meta: {
                            title: "Editar template de mensagem HSM",
                            breadcrumb: [
                                ...SettingsBreadcrumbs,
                                {
                                    label: "Templates de WhatsApp (HSM)",
                                    href: { name: "settings.talk.templates.hsm.index" },
                                    show: ".*",
                                },
                                {
                                    label: "Editar template HSM",
                                    show: ".*",
                                },
                            ],
                        },
                    },
                ],
            },
            {
                path: "templates/message",
                component: () => import("@/layouts/EmptyRouterLayout"),
                meta: TEMPLATES_MESSAGE_META,
                children: [
                    {
                        name: "settings.talk.templates.message.index",
                        path: "",
                        component: () => import("@/pages/talkSettings/template/message/TalkTemplateMessageIndex"),
                    },
                    {
                        name: "settings.talk.templates.message.create",
                        path: "create",
                        component: () => import("@/pages/talkSettings/template/message/TalkTemplateMessageCreate"),
                        meta: {
                            title: "Novo template de mensagem",
                            breadcrumb: [
                                ...SettingsBreadcrumbs,
                                {
                                    label: "Templates de mensagem",
                                    href: { name: "settings.talk.templates.message.index" },
                                    show: ".*",
                                },
                                {
                                    label: "Criar template",
                                    show: ".*",
                                },
                            ],
                        },
                    },
                    {
                        name: "settings.talk.templates.message.edit",
                        path: ":id/edit",
                        props: true,
                        component: () => import("@/pages/talkSettings/template/message/TalkTemplateMessageEdit"),
                        meta: {
                            title: "Editar template de mensagem",
                            breadcrumb: [
                                ...SettingsBreadcrumbs,
                                {
                                    label: "Templates de mensagem",
                                    href: { name: "settings.talk.templates.message.index" },
                                    show: ".*",
                                },
                                {
                                    label: "Editar template",
                                    show: ".*",
                                },
                            ],
                        },
                    },
                ],
            },
            {
                name: "settings.talk.blacklist-recipients.phones.index",
                path: "blacklist-recipients/phones",
                component: () => import("@/pages/talkSettings/blacklistRecipient/phone/TalkBlacklistPhoneIndex"),
                meta: BLACKLIST_PHONES_META,
                children: [
                    {
                        name: "settings.talk.blacklist-recipients.phones.create",
                        path: "create",
                        component: () => import("@/pages/talkSettings/blacklistRecipient/phone/TalkBlacklistPhoneCreate"),
                    },
                    {
                        name: "settings.talk.blacklist-recipients.phones.edit",
                        path: ":id/edit",
                        component: () => import("@/pages/talkSettings/blacklistRecipient/phone/TalkBlacklistPhoneEdit"),
                        props: true,
                    },
                ],
            },
        ],
    },
]

function onlyAuthorizedRouteEnter(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    if (!currentUser.account.flags.allows_talk_attendance) {
        return next({ name: "notfound", query: { errorId: 403 } })
    }

    next()
}
