import { getStore } from "@/store"
import environment from "@/shared/environment"

const viewAttendanceProps = route => ({ attendanceId: parseInt(route.params.attendanceId) })

const newChatProps = route => ({
    pipelineId: route.query.pipelineId ? parseInt(route.query.pipelineId) : null,
    stageId: route.query.stageId ? parseInt(route.query.stageId) : null,
    recipientId: parseInt(route.query.recipientId),
    recipientEntity: parseInt(route.query.recipientEntity),
    recipientTo: Array.isArray(route.query.recipientTo) ? route.query.recipientTo : [route.query.recipientTo],
    dealsIds: Array.isArray(route.query.dealsIds) ? route.query.dealsIds : null,
    channelId: parseInt(route.query.channelId),
    forceShowForm: !!route.query.forceShowForm,
})

export default [
    {
        path: "talk/attendances",
        component: () => import("@/layouts/DefaultLayout"),
        beforeEnter: onlyAuthorizedRouteEnter,
        children: [
            {
                path: "",
                name: "talk.attendances",
                component: () => import("@/pages/talk/attendances/AttendanceIndex"),
                meta: {
                    title: "Listagem de atendimentos",
                    breadcrumb: [
                        {
                            label: "Home",
                            href: environment.APP_YII,
                            show: ".*",
                            external: true,
                        },
                        {
                            label: "Listagem de atendimentos",
                            show: ".*",
                        },
                    ],
                },
            },
        ],
    },
    {
        path: "talk/chat",
        component: () => import("@/layouts/FullContentLayout"),
        beforeEnter: onlyAuthorizedRouteEnter,
        children: [
            {
                path: "",
                name: "talk.chat",
                component: () => import("@/pages/talk/chat/ChatIndex"),
                children: [
                    {
                        path: ":attendanceId(\\d+)",
                        name: "talk.chat.show",
                        props: viewAttendanceProps,
                        component: () => import("@/pages/talk/chat/view/ViewAttendance"),
                    },
                    {
                        path: "new",
                        name: "talk.chat.new",
                        component: () => import("@/pages/talk/chat/new/ChatNew"),
                        props: newChatProps,
                        meta: { transition: true },
                        beforeEnter(to, from, next) {
                            const currentUser = getStore().getters["auth/currentUser"]
                            const hasG1Permission = getStore().getters["auth/hasG1Permission"]

                            if (!currentUser.talk_start_attendance && !hasG1Permission) {
                                return next({ name: "notfound", query: { errorId: 403 } })
                            }

                            next()
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "talk/embed/chat",
        component: () => import("@/layouts/EmptyRouterLayout"),
        meta: {
            embed: true,
        },
        beforeEnter: onlyAuthorizedRouteEnter,
        children: [
            {
                path: ":attendanceId(\\d+)",
                name: "talk.embed.chat.show",
                props: viewAttendanceProps,
                component: () => import("@/pages/talk/chat/embed/ViewEmbedAttendance"),
            },
            {
                path: "new",
                name: "talk.embed.chat.new",
                props: newChatProps,
                component: () => import("@/pages/talk/chat/embed/ViewEmbedNewChat"),
            },
        ],
    },
]

export function inIframePreventLeave(to, from, next) {
    const isInIframe = window.self !== window.top
    if (isInIframe && !to.meta.embed) {
        next(false)

        return
    }

    next()
}

function onlyAuthorizedRouteEnter(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    if (!currentUser.account.flags.allows_talk_attendance || !currentUser.talk_user_as_attendant) {
        return next({ name: "notfound", query: { errorId: 403 } })
    }

    next()
}
