import PipeLogin from "@/pages/login/PipeLogin"
import ImpersonateLogin from "@/pages/login/ImpersonateLogin"
import PipeLoginMax from "@/pages/login/PipeLoginMax"

export default [
    {
        name: "login.auth",
        path: "/v2/login",
        meta: { isPublic: true },
        props: true,
        component: PipeLogin,
    },
    {
        name: "login.impersonate",
        path: "/v2/login/impersonate/:hashId",
        meta: { isPublic: true },
        props: true,
        component: ImpersonateLogin,
    },
    {
        name: "recoverPassword.auth",
        path: "/v2/recover",
        meta: { isPublic: true },
        props: {
            show: "recoverPassword",
        },
        component: PipeLogin,
    },
    {
        name: "changePassword.auth",
        path: "/v2/recover/:userHash",
        meta: { isPublic: true },
        props: {
            show: "changePassword",
        },
        component: PipeLogin,
    },
    {
        name: "newPassword.auth",
        path: "/v2/new-password/:userHash",
        meta: { isPublic: true },
        props: {
            show: "newPassword",
        },
        component: PipeLogin,
    },
    {
        name: "3rdParty.login",
        path: "/v2/login/:uri",
        meta: { isPublic: true },
        props: {
            show: "3rdPartyLogin",
        },
        component: PipeLogin,
    },
    {
        name: "3rdPartyRedirect.auth",
        path: "/v2/login/:uri/redirect",
        meta: { isPublic: true },
        component: () => import("@/pages/login/3rdPartyLoginRedirect"),
    },
    {
        name: "login.pendingApproval",
        path: "/v2/pending-approval",
        meta: { isPublic: true },
        props: {
            show: "PendingApproval",
        },
        component: PipeLogin,
    },
    {
        name: "piperunMax.login",
        path: "/v2/piperun-max/login",
        component: PipeLoginMax,
    },
]
