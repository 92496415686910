import environment from "@/shared/environment"
import { getStore } from "@/store"

export default [
    {
        path: "goals-new",
        component: () => import("@/layouts/EmptyRouterLayout"),
        beforeEnter(to, from, next) {
            const currentUser = getStore().getters["auth/currentUser"]
            if (!currentUser.account.flags.allows_v2_goals) {
                return next({ name: "goals.index" })
            }

            next()
        },
        children: [
            {
                path: "",
                redirect: { name: "goals-new.index.stats" },
            },
            {
                name: "goals-new.index",
                path: "",
                component: () => import("@/pages/goalsNew/GoalsNewIndex"),
                children: [
                    {
                        name: "goals-new.index.list",
                        path: "list",
                        component: () => import("@/pages/goalsNew/GoalsNewList"),
                        props: true,
                        meta: {
                            title: "Listagem de Metas",
                            breadcrumb: [
                                {
                                    label: "Home",
                                    href: environment.APP_YII,
                                    show: ".*",
                                    external: true,
                                },
                                {
                                    label: "Metas V2",
                                    show: ".*",
                                },
                            ],
                        },
                    },
                    {
                        name: "goals-new.index.stats",
                        path: "stats",
                        component: () => import("@/pages/goalsNew/GoalsNewStats"),
                        props: true,
                        meta: {
                            title: "Estatísticas de Metas",
                            breadcrumb: [
                                {
                                    label: "Home",
                                    href: environment.APP_YII,
                                    show: ".*",
                                    external: true,
                                },
                                {
                                    label: "Metas V2",
                                    show: ".*",
                                },
                            ],
                        },
                    },
                ],
            },
            {
                name: "goals-new.create",
                path: "create",
                component: () => import("@/pages/goalsNew/GoalsNewCreate"),
                meta: {
                    title: "Cadastrar meta",
                    breadcrumb: [
                        {
                            label: "Home",
                            href: environment.APP_YII,
                            show: ".*",
                            external: true,
                        },
                        {
                            label: "Metas V2",
                            href: "/v2/goals-new",
                            show: ".*",
                        },
                        {
                            label: "Cadastrar meta",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "goals-new.edit",
                path: "edit/:id",
                component: () => import("@/pages/goalsNew/GoalsNewEdit"),
                props: true,
                meta: {
                    title: "Editar meta",
                    breadcrumb: [
                        {
                            label: "Home",
                            href: environment.APP_YII,
                            show: ".*",
                            external: true,
                        },
                        {
                            label: "Metas V2",
                            href: "/v2/goals-new",
                            show: ".*",
                        },
                        {
                            label: "Editar",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "goals-new.duplicate",
                path: "duplicate/:id",
                component: () => import("@/pages/goalsNew/GoalsNewEdit"),
                props: true,
                meta: {
                    title: "Duplicar meta",
                    breadcrumb: [
                        {
                            label: "Home",
                            href: environment.APP_YII,
                            show: ".*",
                            external: true,
                        },
                        {
                            label: "Metas V2",
                            href: "/v2/goals-new",
                            show: ".*",
                        },
                        {
                            label: "Duplicar",
                            show: ".*",
                        },
                    ],
                },
            },
        ],
    },
]
