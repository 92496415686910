<template>
    <div style="text-align: center;">
        <pipe-alert
            v-show="!loginSuccess && error"
            center-title
            title="Não foi possível fazer o login."
        >
            {{ error }}
        </pipe-alert>
    </div>
</template>

<script>
import RedirectHandler from "@/mixins/RedirectHandler"
import { mapActions } from "vuex"
import PipeAlert from "@/components/v2/PipeAlert"

export default {
    components: {
        PipeAlert,
    },
    mixins: [
        RedirectHandler,
    ],
    data() {
        return {
            error: null,
        }
    },
    computed: {
        impersonateHash() {
            if (this.$route.params.hashId) {
                return this.$route.params.hashId
            }

            return false
        },
    },
    created() {
        if (this.impersonateHash) {
            this.loginImpersonate()
        }
    },
    methods: {
        ...mapActions({
            "authImpersonateWithCredentials": "auth/authImpersonateWithCredentials",
        }),
        async loginImpersonate() {
            this.error = null
            try {
                await this.authImpersonateWithCredentials(this.impersonateHash)
                this.loginSuccess = true

                return this.redirectHandler("/pipeline")
            } catch (error) {
                this.error = error
                this.loginSuccess = false
            }
        },
    },
}
</script>
