export default {
    semantic: {
        formField: {
            borderRadius: "4px",
            paddingX: "6px",
            paddingY: "8px",
        },
        colorScheme: {
            formField: {
                borderColor: "#E5E7EB", //@primary-gray-border
            },
            text: {
                color: "#676a6c",
            },
        },
    },
}
