import { computed, ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import useModalNotification from "@/composables/modalNotification"
import RouteVariables from "@/shared/variables/RouteVariables"
import { getStore } from "@/store"
import environment from "@/shared/environment"
import { ACCOUNT_FLAG_ALLOWS_ACTIVITIES_V2, ACCOUNT_FLAGS_DEPENDENCY } from "@/router/routes/settings/variables/AccountFlagsVariables"

export default function useValidateFeatureFlag() {
    const store = useStore()
    const router = useRouter()
    const user = computed(() => store.getters["auth/currentUser"])
    const showModal = ref(false)

    async function handleAllowedPlanFeature(routeName) {
        await checkRouteFlags(routeName)
        if (!showModal.value) {
            return
        }

        return showCommercialPlanNotAllowed(user.value.name)
    }

    async function checkRouteFlags(routeName) {
        const routePermissions = {
            [RouteVariables.ROUTE_NAME_FORECAST]: "allows_forecast",
            [RouteVariables.ROUTE_NAME_ACTIVITIES_MAP]: "allows_activities_map",
            [RouteVariables.ROUTE_NAME_CONTENT_CATEGORIES]: "allows_content_categories",
            [RouteVariables.ROUTE_NAME_TALK_ATTENDANCE]: "allows_talk_attendance",
            [RouteVariables.ROUTE_NAME_HORUS_BI]: "allows_bi_horusbi",
        }
        const permission = routePermissions[routeName]

        if (!permission) {
            return
        }

        if (permission && user.value.account.flags[permission]) {
            await router.push({ name: routeName }).catch(() => {})

            return
        }

        showModal.value = true
    }

    return {
        handleAllowedPlanFeature,
    }
}

export function showCommercialPlanNotAllowed(userName) {
    return useModalNotification().warning(
        `Olá, ${userName}`,
        `Essa funcionalidade não está disponível em seu plano. Para ter acesso, entre em contato com nosso time de contratos por e-mail
                ou clique no botão abaixo.<br>
                <div class="m-t-sm">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    <a style="font-size: 13px;" href="mailto:comercial@crmpiperun.com">
                        comercial@crmpiperun.com
                    </a>
                    <div class="m-t-sm">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                         <a style="font-size: 13px; color: #337ab7; text-decoration: none;" href="https://web.whatsapp.com/send?phone=558000138472&text&app_absent=1" target="_blank">
                            +558000138472
                        </a>
                    </div>
                </div>
            `,
        "Falar com contratos",
        "Cancelar",
        true,
        {},
        {},
        {
            preConfirm: () => {
                window.open("https://web.whatsapp.com/send?phone=558000138472&text&app_absent=1", "_blank")
            },
        }
    )
}

export function redirectIfNotAllowed(flag, redirectRoute, route = null) {
    return function (to, from, next) {
        const currentUser = getStore().getters["auth/currentUser"]
        if (!currentUser.account.flags[flag]) {
            return next({ name: redirectRoute })
        }

        route ? next({ name: route }) : next()
    }
}

export function onlyAllowedFlag(flag) {
    return function (to, from, next) {
        const currentUser = getStore().getters["auth/currentUser"]

        if (!currentUser.account.flags[flag] && flag === ACCOUNT_FLAG_ALLOWS_ACTIVITIES_V2) {
            return window.location = `${environment.APP_YII}/atividade`
        } else if (!currentUser.account.flags[flag]) {
            return next({ name: "notfound", query: { errorId: 403 } })
        }

        next()
    }
}

export function processFlagsDependency(flags) {
    Object.keys(flags).forEach(dependentFlag => {
        if (ACCOUNT_FLAGS_DEPENDENCY[dependentFlag]) {
            ACCOUNT_FLAGS_DEPENDENCY[dependentFlag].forEach(mainFlag => {
                if (!flags[mainFlag]) {
                    flags[dependentFlag] = false
                }
            })
        }
    })

    return flags
}

