export const ROUTE_NAME_FORECAST = "forecast.index"
export const ROUTE_NAME_ACTIVITIES_MAP = "activities.map"
export const ROUTE_NAME_CONTENT_CATEGORIES = "categories.index"
export const ROUTE_NAME_TALK_ATTENDANCE = "talk.chat"
export const ROUTE_NAME_HORUS_BI = "HorusBiIndex.index"

export default {
    ROUTE_NAME_FORECAST,
    ROUTE_NAME_ACTIVITIES_MAP,
    ROUTE_NAME_CONTENT_CATEGORIES,
    ROUTE_NAME_TALK_ATTENDANCE,
    ROUTE_NAME_HORUS_BI,
}
