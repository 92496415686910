<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { EventBus } from "@/plugins/event-bus"
import { clearTokens, getTokens } from "@/plugins/auth"
import axios from "axios"
import { flatMap } from "lodash-es"
import {
    usePushNotification,
    LISTEN_TOKEN_REVOKED,
    LISTEN_EXPORT_SUCCESSFUL,
    LISTEN_ACTIVITY_ALERT,
    LISTEN_EXPORT_FAILED,
    LISTEN_BULK_ACTION_SUCCESSFUL,
    LISTEN_BULK_ACTION_FAILED,
    LISTEN_IMPORT_FAILED,
    LISTEN_IMPORT_SUCCESSFUL,
    LISTEN_TRANSCRIBE_FAILED,
    LISTEN_TRANSCRIBE_SUCCESSFUL,
    LISTEN_ANALYZE_FAILED,
    LISTEN_ANALYZE_SUCCESSFUL,
    LISTEN_GOAL_RECURRENCE_FAILED,
    LISTEN_GOAL_RECURRENCE_SUCCESSFUL,
} from "@/composables/pushNotification"
import { http } from "@/plugins/http"
import useModalNotification from "@/composables/modalNotification"

export default {
    name: "App",
    setup() {
        return {
            pushNotification: usePushNotification(),
            modalNotification: useModalNotification(),
        }
    },
    data() {
        return {
            modalOpen: false,
            channel: null,
        }
    },
    created() {
        const bodyElement = document.querySelector("body")
        EventBus.$on("modal-open", isOpen => {
            if (isOpen) {
                return bodyElement.classList.add("modal-open")
            }

            bodyElement.classList.remove("modal-open")
        })

        this.startNotificationListeners()

        if (typeof BroadcastChannel !== "undefined") {
            this.channel = new BroadcastChannel("pprn.broadcast.channel")
            this.channel.addEventListener("message", (event) => {
                if (event.data?.renewedLogin === true) {
                    this.renewedLogin()
                }
            })
        }
    },
    beforeUnmount() {
        EventBus.$off("modal-open")

        this.pushNotification.unsubscribe(LISTEN_TOKEN_REVOKED, this.logout)
        this.pushNotification.unsubscribe(LISTEN_ACTIVITY_ALERT, this.activityAlert)

        //Exportação
        this.pushNotification.unsubscribe(LISTEN_EXPORT_SUCCESSFUL, this.exportSuccessful)
        this.pushNotification.unsubscribe(LISTEN_EXPORT_FAILED, this.exportFailed)

        //Ações em massa
        this.pushNotification.unsubscribe(LISTEN_BULK_ACTION_SUCCESSFUL, this.bulkActionSuccessful)
        this.pushNotification.unsubscribe(LISTEN_BULK_ACTION_FAILED, this.bulkActionFailed)

        //Importação
        this.pushNotification.unsubscribe(LISTEN_IMPORT_SUCCESSFUL, this.importSuccessful)
        this.pushNotification.unsubscribe(LISTEN_IMPORT_FAILED, this.importFailed)

        //Call - Transcribe
        this.pushNotification.unsubscribe(LISTEN_TRANSCRIBE_SUCCESSFUL, this.transcribeSuccessful)
        this.pushNotification.unsubscribe(LISTEN_TRANSCRIBE_FAILED, this.transcribeFailed)

        //Call - Analyze
        this.pushNotification.unsubscribe(LISTEN_ANALYZE_FAILED, this.analyzeSuccessful)
        this.pushNotification.unsubscribe(LISTEN_ANALYZE_SUCCESSFUL, this.analyzeFailed)

        //Goal - Recurrence
        this.pushNotification.unsubscribe(LISTEN_GOAL_RECURRENCE_FAILED, this.recurrenceFailed)
        this.pushNotification.unsubscribe(LISTEN_GOAL_RECURRENCE_SUCCESSFUL, this.recurrenceSuccessful)
    },
    methods: {
        startNotificationListeners() {
            this.pushNotification.subscribe(LISTEN_TOKEN_REVOKED, this.logout)
            this.pushNotification.subscribe(LISTEN_ACTIVITY_ALERT, this.activityAlert)

            //Exportação
            this.pushNotification.subscribe(LISTEN_EXPORT_SUCCESSFUL, this.exportSuccessful)
            this.pushNotification.subscribe(LISTEN_EXPORT_FAILED, this.exportFailed)

            //Ações em massa
            this.pushNotification.subscribe(LISTEN_BULK_ACTION_SUCCESSFUL, this.bulkActionSuccessful)
            this.pushNotification.subscribe(LISTEN_BULK_ACTION_FAILED, this.bulkActionFailed)

            //Importação
            this.pushNotification.subscribe(LISTEN_IMPORT_SUCCESSFUL, this.importSuccessful)
            this.pushNotification.subscribe(LISTEN_IMPORT_FAILED, this.importFailed)

            //Call - Transcribe
            this.pushNotification.subscribe(LISTEN_TRANSCRIBE_SUCCESSFUL, this.transcribeSuccessful)
            this.pushNotification.subscribe(LISTEN_TRANSCRIBE_FAILED, this.transcribeFailed)

            //Call - Analyze
            this.pushNotification.subscribe(LISTEN_ANALYZE_FAILED, this.analyzeSuccessful)
            this.pushNotification.subscribe(LISTEN_ANALYZE_SUCCESSFUL, this.analyzeFailed)

            //Goal - Recurrence
            this.pushNotification.subscribe(LISTEN_GOAL_RECURRENCE_FAILED, this.recurrenceFailed)
            this.pushNotification.subscribe(LISTEN_GOAL_RECURRENCE_SUCCESSFUL, this.recurrenceSuccessful)
        },
        renewedLogin() {
            if (this.$route.name === "login.auth" || this.pushNotification.hasListeners()) {
                return
            }

            http.get("/token/check", { headers: { Authorization: getTokens().access_token || "" } })
                .then(() => {
                    this.pushNotification.restoreListenersBackup()

                    this.modalNotification.info(
                        "Login realizado!",
                        "Sua conexão foi restabelecida com sucesso.",
                        "info",
                        "OK",
                        "10000"
                    )
                })
                .catch(() => {})
        },
        logout() {
            if (this.$route.name === "login.auth") {
                return
            }

            http.get("/token/check", { headers: { Authorization: getTokens().access_token || "" } })
                .catch(() => {
                    axios.get("/logout").catch(() => {})
                    clearTokens()
                    this.pushNotification.unsubscribeAll()

                    this.modalNotification.danger(
                        "Sessão invalidada",
                        "Você foi desconectado porque sua sessão foi encerrada ou outro computador fez um acesso com seu login."
                    ).then(() => {
                        return this.$router.push({ name: "login.auth" })
                    })
                })
        },
        async activityAlert(data) {
            let btnConfirm = "OK"
            let btnCancel = ""

            if (data.url) {
                btnConfirm = "Visualizar atividade"
                btnCancel = "Fechar"
            }

            const { value } = await this.modalNotification.warning(
                `${data.title}`,
                `${data.body}`,
                `${btnConfirm}`,
                `${btnCancel}`
            )

            if (!value) {
                return
            }

            window.location = data.url + "#atividades"
        },
        async importFailed(data) {
            if (this.$route.name === "imports.history") {
                return
            }

            let dataMessages = data.messages ?? ""
            dataMessages = typeof dataMessages === "object" && !Array.isArray(dataMessages) ? Object.values(dataMessages) : dataMessages
            const messages = Array.isArray(dataMessages) ? dataMessages.map((message) => Array.isArray(message) ? flatMap(message).join("; ") : message) : []

            const { value } = await this.modalNotification.warning(
                "Falha na importação",
                `Erros: ${flatMap(messages).join("; ")}`,
                "Visualizar importações",
                "Fechar"
            )

            if (!value) {
                return
            }

            return this.$router.push({ name: "imports.history" })
        },
        async importSuccessful() {
            if (this.$route.name === "imports.history") {
                return
            }

            const { value } = await this.modalNotification.success(
                "Sucesso na importação",
                "A importação foi concluída com sucesso!",
                "Visualizar importações",
                "Fechar"
            )

            if (!value) {
                return
            }

            return this.$router.push({ name: "imports.history" })
        },
        async exportFailed(data) {
            if (["bulkActions.main", "bulkActions.history", "exports.history"].includes(this.$route.name)) {
                return
            }

            let body = "Exportação falhou e não foi possível ser concluída."
            if (data.export && data.export.id) {
                body = `Exportação (#${data.export.id}) falhou e não foi possível ser concluída.`
            }

            const { value } = await this.modalNotification.warning(
                "Falha na exportação",
                `${body}`,
                "Visualizar exportações",
                "Fechar"
            )

            if (!value) {
                return
            }

            return this.$router.push({ name: "exports.history" })
        },
        async exportSuccessful(data) {
            if (["bulkActions.main", "bulkActions.history", "exports.history"].includes(this.$route.name)) {
                return
            }

            const body = data.export?.id ? `Exportação (#${data.export.id}) concluída com sucesso!` : "Exportação concluída com sucesso!"
            const { value } = await this.modalNotification.success(
                "Sucesso na exportação",
                `${body}`,
                "Visualizar exportações",
                "Fechar"
            )

            if (!value) {
                return
            }

            return this.$router.push({ name: "exports.history" })
        },
        async bulkActionFailed(data) {
            if (this.$route.name === "bulkActions.history") {
                return
            }

            let body = "Ação em massa falhou e não foi possível ser concluída."
            if (data.historyId) {
                body = `Ação em massa (#${data.historyId}) falhou e não foi possível ser concluída.`
            }

            const { value } = await this.modalNotification.warning(
                "Falha na ação em massa",
                `${body}`,
                "Visualizar ações em massa",
                "Fechar"
            )

            if (!value) {
                return
            }

            return this.$router.push({ name: "bulkActions.history" })
        },
        async bulkActionSuccessful(data) {
            if (this.$route.name === "bulkActions.history") {
                return
            }

            const body = data.historyId ? `Ação em massa (#${data.historyId}) concluída com sucesso!` : "Ação em massa concluída com sucesso!"
            const { value } = await this.modalNotification.success(
                "Sucesso na ação em massa",
                `${body}`,
                "Visualizar ações em massa",
                "Fechar"
            )

            if (!value) {
                return
            }

            return this.$router.push({ name: "bulkActions.history" })
        },
        async transcribeFailed(data) {
            if (!data.call?.id || !data.call?.deal_id) {
                return
            }

            const body = `Não foi possível completar a transcrição da ligação (#${data.call.id}).`
            const { value } = await this.modalNotification.warning(
                "Falha na transcrição da ligação",
                `${body}`,
                "Visualizar ligação",
                "Fechar"
            )

            if (!value) {
                return
            }

            window.location = `/pipeline/gerenciador/visualizar/${data.call.deal_id}#ligacao`
        },
        async transcribeSuccessful(data) {
            if (!data.call?.id || !data.call?.deal_id) {
                return
            }

            const body = `Transcrição da ligação (#${data.call.id}) concluída com sucesso!`
            const { value } = await this.modalNotification.success(
                "Sucesso na transcrição da ligação",
                `${body}`,
                "Visualizar ligação",
                "Fechar"
            )

            if (!value) {
                return
            }

            window.location = `/pipeline/gerenciador/visualizar/${data.call.deal_id}#ligacao`
        },
        async analyzeFailed(data) {
            if (!data.call?.id || !data.call?.deal_id) {
                return
            }

            const body = `Não foi possível completar a análise da ligação (#${data.call.id}).`
            const { value } = await this.modalNotification.warning(
                "Falha na análise da ligação",
                `${body}`,
                "Visualizar ligações",
                "Fechar"
            )

            if (!value) {
                return
            }

            window.location = `/pipeline/gerenciador/visualizar/${data.call.deal_id}#ligacao`
        },
        async analyzeSuccessful(data) {
            if (!data.call?.id || !data.call?.deal_id) {
                return
            }

            const body = `Análise da ligação (#${data.call.id}) concluída com sucesso!`
            const { value } = await this.modalNotification.success(
                "Sucesso na análise da ligação",
                `${body}`,
                "Visualizar ligação",
                "Fechar"
            )

            if (!value) {
                return
            }

            window.location = `/pipeline/gerenciador/visualizar/${data.call.deal_id}#ligacao`
        },
        async recurrenceFailed() {
            if (["goals-new.index.list", "goals-new.index.stats"].includes(this.$route.name)) {
                return
            }

            const { value } = await this.modalNotification.warning(
                "Criação de recorrência incompleta",
                "Não foi possível concluir a criação de recorrência da meta.",
                "Visualizar metas",
                "Fechar"
            )

            if (!value) {
                return
            }

            return this.$router.push({ name: "goals-new.index.list" })
        },
        async recurrenceSuccessful() {
            if (["goals-new.index.list"].includes(this.$route.name)) {
                return
            }

            const { value } = await this.modalNotification.success(
                "Sucesso na criação de recorrência",
                "Recorrência concluída com sucesso!",
                "Visualizar metas",
                "Fechar"
            )

            if (!value) {
                return
            }

            return this.$router.push({ name: "goals-new.index.list" })
        },
    },
}
</script>

<style lang="less">
@import "src/assets/styles/public-styles.less";
@import "src/assets/styles/transitions.less";

html,
body {
    background: white;
}

#app {
    width: 100%;
    min-height: 100%;
    height: 100%;
}

.pipe-content {
    width: 100%;
    height: auto;
    min-height: 100%;
    padding-left: 0;
    background: #f3f3f3;
    overflow: hidden;
}

.dropdown {
    &:hover {
        .dropdown-menu {
            display: block;
        }
    }

    a:hover {
        color: #666;
    }
}

.navbar-top-links {
    li a {
        padding: 10px 10px !important;
    }

    .dropdown-menu {
        background-color: #efefef;
    }
}

label + .radio {
    margin-top: 5px;
}

.maior {
    .form-control {
        min-height: 40px;
    }

    .btn {
        min-height: 40px;
    }
}

.label-error {
    color: #ed5565;
}

.input-sm {
    font-size: 1em;
}

label.required {
    &:after {
        vertical-align: middle;
        content: "\f069";
        padding: 0 3px 3px 0;
        display: inline-block;
        font: normal normal normal 8px/1 FontAwesome;
        font-family: FontAwesome, Arial, Helvetica, sans-serif;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #f7807c;
        margin-left: 1px;
    }
}

.swal2-popup {
    font-size: 1.4rem !important;
}

.toolbar {
    display: flex;
    align-items: center;
    position: relative;
    background: #fff;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    &__section {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 6px;

        &.end {
            justify-content: flex-end;
        }

    }
}

/* Spin loader */
.p-spin-loader {
    .fa-refresh {
        font-size: 24px;
        color: @grey-4;
        animation: spin infinite 1000ms linear;
        transform: rotate(0deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(-360deg);
    }

    to {
        transform: rotate(0deg);
    }
}
/* transition name=grow */
.grow-enter-active,
.grow-leave-active {
    transition: all 0.1s ease-out;
    transform-origin: top;
}

.grow-enter-from,
.grow-leave-to {
    transform: scaleY(0);
    opacity: 0;
}

.btn.btn-gray {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
}

/* Scrollbar */
.thin-scrollbar {
    &::-webkit-scrollbar-track {
        border: 4px solid transparent;
        box-shadow: inset 0 0 10px #ddd;
        border-radius: 250px;
    }

    &::-webkit-scrollbar {
        width: 14px;
        height: 14px;
    }

    &::-webkit-scrollbar-thumb {
        background: #999;
        border-radius: 250px;
        border: 4px solid transparent;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.swal2-content .pipe-alert {
    &__title {
        margin: 10px 0 25px 0;
        font-size: 24px;
        font-weight: 600;
    }
}

.dev-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2.5px 100px;
    background-color: #d75345;
    color: #fff;
    text-align: center;
    z-index: 11;

    a {
        color: #FFF;
        font-weight: bold;
    }
}

._elevio_launcher {
    display: none !important;
}
</style>
