import environment from "@/shared/environment"
import { getStore } from "@/store"
import { COMMISSION_TYPE_ROLE_INVOLVED_IN_FORECAST_PAYMENT } from "@/pages/account/variables/CommissionTypeVariables"

export default [
    {
        name: "commissions.index",
        path: "commissions",
        component: () => import("@/pages/commissions/CommissionIndex"),
        beforeEnter: onlyAllowedCommissions,
        meta: {
            title: "Comissões",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Comissões",
                    href: "",
                    show: ".*",
                },
            ],
        },
    },
]

function onlyAllowedCommissions(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    const customConfigStore = getStore().getters["customConfig/get"]

    if (!currentUser.account.flags.allows_forecast || parseInt(customConfigStore.COMMISSION_TYPE) !== COMMISSION_TYPE_ROLE_INVOLVED_IN_FORECAST_PAYMENT) {
        return next({ name: "notfound", query: { errorId: 403 } })
    }

    next()
}
