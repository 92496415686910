import {
    importCompanyXlsxFileModel,
    importDealXlsxFileModel,
    importPersonXlsxFileModel,
    importUsersXlsxFileModel,
} from "@/plugins/pipe-static-resources"

export const IMPORT_ENTITY_DEAL = 1
export const IMPORT_ENTITY_PERSON = 2
export const IMPORT_ENTITY_COMPANY = 3
export const IMPORT_ENTITY_ACTIVITY = 4
export const IMPORT_ENTITY_LOST_REASON = 5
export const IMPORT_ENTITY_PRODUCT = 6
export const IMPORT_ENTITY_REGION_AND_MICROREGION = 7
export const IMPORT_ENTITY_USER = 8

const ARTICLE_BASE_URL = "https://ajuda.crmpiperun.com/pt-br/articles/"

export const IMPORT_ENTITY_NAME_MAP = new Map([
    [IMPORT_ENTITY_DEAL, "deal"],
    [IMPORT_ENTITY_COMPANY, "company"],
    [IMPORT_ENTITY_PERSON, "person"],
])

export const IMPORT_ENTITY_ARTICLE_MAP = new Map([
    [IMPORT_ENTITY_DEAL, `${ARTICLE_BASE_URL}477-como-utilizar-a-nova-tela-de-importaes-beta`],
    [IMPORT_ENTITY_COMPANY, `${ARTICLE_BASE_URL}510-como-importar-empresas-beta`],
    [IMPORT_ENTITY_PERSON, `${ARTICLE_BASE_URL}509-como-importar-pessoas-beta`],
])

export const IMPORT_ENTITY_LABEL_MAP = new Map([
    [IMPORT_ENTITY_DEAL, "Oportunidades"],
    [IMPORT_ENTITY_COMPANY, "Empresas"],
    [IMPORT_ENTITY_PERSON, "Pessoas"],
    [IMPORT_ENTITY_ACTIVITY, "Atividades"],
    [IMPORT_ENTITY_LOST_REASON, "Motivos de perda"],
    [IMPORT_ENTITY_PRODUCT, "Produtos"],
    [IMPORT_ENTITY_REGION_AND_MICROREGION, "Regiões e microrregiões"],
    [IMPORT_ENTITY_USER, "Usuários"],
])

export const IMPORT_ENTITY_ICON_MAP = new Map([
    [IMPORT_ENTITY_DEAL, "star"],
    [IMPORT_ENTITY_PERSON, "user"],
    [IMPORT_ENTITY_COMPANY, "building"],
    [IMPORT_ENTITY_ACTIVITY, "calendar-o"],
    [IMPORT_ENTITY_LOST_REASON, "database"],
    [IMPORT_ENTITY_PRODUCT, "trash-o"],
    [IMPORT_ENTITY_REGION_AND_MICROREGION, "map-marker"],
    [IMPORT_ENTITY_USER, "user-circle"],
])

export const IMPORT_ENTITIES = [
    {
        entity: IMPORT_ENTITY_DEAL,
        name: IMPORT_ENTITY_LABEL_MAP.get(IMPORT_ENTITY_DEAL),
        description: "Importe oportunidades para criar registros ou atualizar os existentes.",
        route: { name: "importsV3.new.deal" },
        active: true,
        fileModel: importDealXlsxFileModel,
    },
    {
        entity: IMPORT_ENTITY_COMPANY,
        name: IMPORT_ENTITY_LABEL_MAP.get(IMPORT_ENTITY_COMPANY),
        description: "Importe empresas para criar registros ou atualizar os existentes.",
        route: { name: "importsV3.new.company" },
        active: true,
        fileModel: importCompanyXlsxFileModel,
    },
    {
        entity: IMPORT_ENTITY_PERSON,
        name: IMPORT_ENTITY_LABEL_MAP.get(IMPORT_ENTITY_PERSON),
        description: "Importe pessoas para criar registros ou atualizar os existentes.",
        route: { name: "importsV3.new.person" },
        active: true,
        fileModel: importPersonXlsxFileModel,
    },
    {
        entity: IMPORT_ENTITY_ACTIVITY,
        name: IMPORT_ENTITY_LABEL_MAP.get(IMPORT_ENTITY_ACTIVITY),
        description: "Importe atividades para criar registros ou atualizar os existentes.",
        route: "",
        active: false,
        fileModel: null,
    },
    {
        entity: IMPORT_ENTITY_LOST_REASON,
        name: IMPORT_ENTITY_LABEL_MAP.get(IMPORT_ENTITY_LOST_REASON),
        description: "Importe motivos de perda para criar registros ou atualizar os existentes.",
        route: "",
        active: false,
        fileModel: null,
    },
    {
        entity: IMPORT_ENTITY_PRODUCT,
        name: IMPORT_ENTITY_LABEL_MAP.get(IMPORT_ENTITY_PRODUCT),
        description: "Importe produtos para criar registros ou atualizar os existentes.",
        route: "",
        active: false,
        fileModel: null,
    },
    {
        entity: IMPORT_ENTITY_REGION_AND_MICROREGION,
        name: IMPORT_ENTITY_LABEL_MAP.get(IMPORT_ENTITY_REGION_AND_MICROREGION),
        description: "Importe regiões e microregiões para criar registros ou atualizar os existentes.",
        route: "",
        active: false,
        fileModel: null,
    },
    {
        entity: IMPORT_ENTITY_USER,
        name: IMPORT_ENTITY_LABEL_MAP.get(IMPORT_ENTITY_USER),
        description: "Importe usuários para criar registros ou atualizar os existentes.",
        route: { name: "importsV3.new.user" },
        active: true,
        fileModel: importUsersXlsxFileModel,
    },
]

export const importEntityOptions = Array.from(IMPORT_ENTITY_LABEL_MAP.keys()).map(importEntity => ({
    id: importEntity,
    name: IMPORT_ENTITY_LABEL_MAP.get(importEntity),
}))
